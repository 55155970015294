<template>
  <div :ref="campaignInfo.uuid">
    <div
      style="position:absolute; z-index:9; right: 5%; top: 1%"
    >
      <b-button
        @click="archivedCampaign(campaignInfo.uuid)"
        :id="`archive_campaign${campaignInfo.uuid}`"
        variant="gradient-secondary"
        class="btn-icon rounded-circle mr-1"
        size="sm"
        :disabled="disabled_archive"
        v-if="!isArchived"
      >
        <feather-icon icon="ArchiveIcon" />
        <b-tooltip :target="`archive_campaign${campaignInfo.uuid}`" triggers="hover" placement="bottom">{{ $t('archiveCampaign') }}</b-tooltip>
      </b-button>
      <b-button
        @click="archivedCampaign(campaignInfo.uuid)"
        :id="`unarchive_campaign${campaignInfo.uuid}`"
        variant="gradient-secondary"
        class="btn-icon rounded-circle mr-1"
        size="sm"
        :disabled="!disabled_archive"
        v-if="isArchived"
      >
        <b-icon-arrow-up-square class="arrowSquare"></b-icon-arrow-up-square>
        <b-tooltip :target="`unarchive_campaign${campaignInfo.uuid}`" triggers="hover" placement="bottom">{{ $t('unarchiveCampaign') }}</b-tooltip>
      </b-button>
      <b-button
        @click="deletedCampaign(campaignInfo.uuid, isArchived)"
        :id="`delete_campaign${campaignInfo.uuid}`"
        variant="gradient-secondary"
        class="btn-icon rounded-circle"
        size="sm"
        :disabled="disabledDeleteCampaign()"
      >
        <feather-icon icon="XIcon" />
        <b-tooltip :target="`delete_campaign${campaignInfo.uuid}`" triggers="hover" placement="bottom">{{ $t('deleteCampaign') }}</b-tooltip>
      </b-button>
    </div>
    <b-card
      :img-src="verifyAsset(campaignInfo.cover)"
      img-alt="Profile Cover Photo"
      img-top
      class="card-profile objetfit"
    >
      <div class="profile-image-wrapper">
        <div class="profile-image p-0">
          <b-avatar
            size="114"
            variant="light"
            :src="verifyAsset(campaignInfo.logo)"
          />
        </div>
      </div>
      <h3 class="text-overflow">{{ campaignInfo.name }}</h3>
      <b-badge
        class="profile-badge"
        variant="light-warning"
        v-if="isArchived"
      >
        {{ $t('oneCampaigninactive')}}
      </b-badge>
      <b-badge
        class="profile-badge"
        variant="light-success"
        v-else
      >
        {{ $t('oneCampaignactive')}}
      </b-badge>
      <hr class="mb-2">

      <!-- follower projects rank -->
      <div v-if="groupRol !== 'realty'" class="mb-1">
        <div class="d-flex justify-content-between flex-wrap col-12">
          <div class="mb-1">
            <h6 class="text-muted font-weight-bolder">
              {{ $t('proposalscard') }}
            </h6>
            <h3 class="mb-0">
              {{ campaignInfo.stats_fields.proposals }}
            </h3>
          </div>
          <div class="mb-1">
            <h6 class="text-muted font-weight-bolder">
              {{$t('productioncard') }}
            </h6>
            <h3 class="mb-0">
              {{ campaignInfo.stats_fields.production }}
            </h3>
          </div>
          <div class="mb-1">
            <h6 class="text-muted font-weight-bolder">
              {{ $t('publishedcard') }}
            </h6>
            <h3 class="mb-0">
              {{ campaignInfo.stats_fields.published }}
            </h3>
          </div>
        </div>
        <b-button
          variant="flat-light"
          class="btn-icon"
          @click="show_collapse = !show_collapse"
        >
          <feather-icon icon="ChevronUpIcon" size="20" v-if="show_collapse"/>
          <feather-icon icon="ChevronDownIcon" size="20" v-else/>
        </b-button>
        <b-collapse v-model="show_collapse">
          <div class="col-12 d-flex justify-content-between flex-wrap">
            <div class="mb-1">
              <h6 class="text-muted font-weight-bolder">
                {{ $t('campaigns.approved') }}
              </h6>
              <h3 class="mb-1">
                {{ campaignInfo.stats_fields.approved }}
              </h3>
            </div>
            <div class="mb-1">
              <h6 class="text-muted font-weight-bolder">
                {{ $t('campaigns.rejected') }}
              </h6>
              <h3 class="mb-1">
                {{ campaignInfo.stats_fields.rejected }}
              </h3>
            </div>
            <div class="mb-1">
              <h6 class="text-muted font-weight-bolder">
                {{ $t('campaigns.pending') }}
              </h6>
              <h3 class="mb-1">
                {{ campaignInfo.stats_fields.pending }}
              </h3>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-between flex-wrap">
            <div class="mb-1">
              <h6 class="text-muted font-weight-bolder">
                {{ $t('campaigns.tradingProfiles') }}
              </h6>
              <h3 class="mb-1">
                {{ campaignInfo.stats_fields.trading }}
              </h3>
            </div>
            <div class="mb-1">
              <h6 class="text-muted font-weight-bolder">
                {{ $t('campaigns.submitted') }}
              </h6>
              <h3 class="mb-1">
                {{ campaignInfo.stats_fields.submitted }}
              </h3>
            </div>
            <div class="mb-1">
              <h6 class="text-muted font-weight-bolder">
                {{ $t('campaigns.onboarding') }}
              </h6>
              <h3 class="mb-1">
                {{ campaignInfo.stats_fields.onboarding }}
              </h3>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-around flex-wrap">
            <div class="mb-1">
              <h6 class="text-muted font-weight-bolder">
                {{ $t('budgetcard') }}
              </h6>
              <h3 class="mb-0">
                $ {{ separatebycomma(campaignInfo.budget) }}
              </h3>
            </div>
            <div class="mb-1" v-if="groupRol !== 'realty'">
              <h6 class="text-muted font-weight-bolder">
                {{ $t('spentcard') }}
              </h6>
              <h3 class="mb-0">
                $ {{ separatebycomma(campaignInfo.stats_fields.spent) }}
              </h3>
            </div>
          </div>
        </b-collapse>
      </div>
      <div v-else class="d-flex justify-content-between align-items-center mb-3">
        <div>
          <h6 class="text-muted font-weight-bolder">
            {{$t('locationsProposal')}}
          </h6>
          <h3 class="mb-0">
            {{ campaignInfo.stats_locations.locations }}
          </h3>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            {{$t('locationsApproved')}}
          </h6>
          <h3 class="mb-0">
            {{ campaignInfo.stats_locations.approved }}
          </h3>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            {{$t('locationsRunning')}}
          </h6>
          <h3 class="mb-0">
            {{ campaignInfo.stats_locations.running }}
          </h3>
        </div>
      </div>
      <b-row v-if="!show_collapse">
        <b-col lg="12">
          <h6 class="text-muted font-weight-bolder">
            {{ $t('budgetcard') }}
          </h6>
          <h3 class="mb-0">
            $ {{ separatebycomma(campaignInfo.budget) }}
          </h3>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="d-flex justify-content-center mb-1">
          <router-link class="botonResponsive d-flex align-items-stretch" :to="getUrl(campaignInfo.uuid)">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="isArchived"
            >
              {{ $t('campaigndetail') }}
            </b-button>
          </router-link>
        </b-col>
        <b-col class="d-flex justify-content-center mb-1">
          <router-link class="botonResponsive d-flex align-items-stretch" :to="'/brief/'+campaignInfo.uuid">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :disabled="isArchived"
            >
              {{ $t('campaignbrief') }}
            </b-button>
          </router-link>
        </b-col>
      </b-row>
      <!--/ follower projects rank -->
    </b-card>
  </div>

</template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BBadge, BButton, BCollapse, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import service from '@/services/dashboard'
import { getUserData, hasPermission } from '@/libs/utils/user'
import { verifyAsset } from '@/libs/utils/urls'

export default {
  props: {
    campaignData: {
      default: () => {}
    },
    campaignArchived: {
      default: () => false
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BButton,
    BCollapse,
    BTooltip,
  },
  directives: {
    Ripple,
  },
  data () {
    return {
      verifyAsset,
      getUserData,
      campaignInfo: this.campaignData,
      isArchived: this.campaignArchived,
      groupRol: '',
      disabled_archive: false,
      show_collapse: false
    }
  },
  beforeCreate () {
    this.$store.commit('appConfig/UPDATE_DROPDOWN_MENU_HIDDEN', true)
  },
  created () {
    this.getGroup()
    if (this.disabledChangeCampaign()) {
      this.disabled_archive = true;
    } else {
      this.disabled_archive = this.isArchived;
    }
  },
  methods: {
    isSuperUser() {
      return getUserData().is_superuser
    },
    getUrl(campaign_uuid) {
      if (this.groupRol === 'realty') return `/realty/campaign/${campaign_uuid}`
      return `/campaign/${campaign_uuid}`
    },
    getGroup () {
      const user = JSON.parse(localStorage.userData)
      this.groupRol = user.groups[0].name
    },
    separatebycomma (num) {
      if (num === null) return 0
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    archivedCampaign(uuid) {
      if (!this.isArchived) {
        this.$swal({
          title: this.$t('campaigns.archivedCampaign?'),
          text: this.$t('campaigns.archivedCampaign'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('campaigns.sure'),
          cancelButtonText: this.$t('campaigns.realty.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false,
          heightAuto: false
        }).then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('uuid', uuid)
            formData.append('active', true)
            formData.append('is_archived', true)
            service.updateCampaign(uuid, formData)
              .then(
                this.$emit('has_been_archived', this.campaignInfo)
              )
          }
        })
      } else {
        this.$swal({
          title: this.$t('campaigns.disarchivedCampaign?'),
          text: this.$t('campaigns.disarchivedCampaign'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('campaigns.sure'),
          cancelButtonText: this.$t('campaigns.realty.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false,
          heightAuto: false
        }).then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('uuid', uuid)
            formData.append('active', true)
            formData.append('is_archived', false)
            service.updateCampaign(uuid, formData)
              .then(
                this.$emit('has_been_desarchived', this.campaignInfo)
              )
          }
        })
      }

    },
    deletedCampaign (uuid, is_archived) {
      if (this.disabledDeleteCampaign()) return;
      this.$swal({
        title: this.$t('campaigns.textConfirmCampaign'),
        text: this.$t('campaigns.textCampaign'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirmCampaign'),
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          const formData = new FormData()
          formData.append('uuid', uuid)
          formData.append('active', false)
          formData.append('is_archived', this.campaignInfo.is_archived)
          service.updateCampaign(uuid, formData)
            .then(() => {
              this.$refs[uuid].parentElement.remove()
              this.$emit("deletedCampaign", is_archived, uuid)
            })
        }
      })
    },
    disabledDeleteCampaign() {
      return !hasPermission('delete_campaign')
    },
    disabledChangeCampaign() {
      return !hasPermission('change_campaign')
    },
  }
}
</script>

<style>
  .b-sidebar {
    width: 600px;
  }
  .objetfit img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .arrowSquare {
    width: 14.5px; 
    height: 14.5px;
  }
  .text-overflow {
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
</style>
